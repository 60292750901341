
import { Options, mixins } from "vue-class-component";
import {
  HasAccessToAssetMixin,
  AlbumAndAssetManagementMixin,
  RoutingUtilityMixin,
} from "@/mixins";
import {
  AssetsInterface,
  AssetType,
  Direction,
  FoldersWithAssetsInterface,
} from "@/types";
import { assetsModule } from "@/store";
import VideoComponent from "./VideoComponent/index.vue";
import ImageComponent from "./ImageComponent/index.vue";
import Model3DComponent from "./Model3DComponent/index.vue";
import AccessDeniedComponent from "./AccessDenied/index.vue";
import ToggleAssetPinButton from "./ToggleAssetPinButton/index.vue";
import SliderArrow from "./SliderArrow/index.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "Slider",
  components: {
    SliderArrow,
    AccessDeniedComponent,
    ToggleAssetPinButton,
    VideoComponent,
    ImageComponent,
    Model3DComponent,
  },
})
export default class Slider extends mixins(
  HasAccessToAssetMixin,
  AlbumAndAssetManagementMixin,
  RoutingUtilityMixin
) {
  protected direction = Direction;
  private mq = useMq();

  protected get dataLoadingInProgress(): boolean {
    return assetsModule.getAllDataLoader;
  }

  private get getFilteredAlbums(): FoldersWithAssetsInterface[] {
    return assetsModule.getFilteredAlbums;
  }

  private get getDefaultAlbums(): FoldersWithAssetsInterface[] {
    return assetsModule.getFoldersWithAssets;
  }

  private get getAssetsData(): AssetsInterface[] {
    const isDesktop = ["lg", "xl", "xxl"].includes(this.mq.current);
    const albums = isDesktop ? this.getFilteredAlbums : this.getDefaultAlbums;

    return this.extractNestedAssets(albums);
  }

  protected get getAsset(): AssetsInterface | undefined {
    return this.getAssetsData.find(
      (a) => a.slug === this.$route.params.assetSlug
    );
  }

  protected get isRouteValid(): boolean {
    const isDesktop = ["lg", "xl", "xxl"].includes(this.mq.current);
    const { albumPath, assetSlug } = this.$route.params;
    const albums = isDesktop ? this.getFilteredAlbums : this.getAlbums;

    const asset = this.findAssetBySlug(albums, assetSlug as string);

    return this.isRoutePathValid(this.$route.path, asset);
  }

  protected get isImage(): AssetType {
    return AssetType.Image;
  }

  protected get isModels(): AssetType {
    return AssetType.Models;
  }

  protected get isVideo(): AssetType {
    return AssetType.Video;
  }

  private handleArrowKeyPress(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      this.moveIndex(-1);
    } else if (event.key === "ArrowDown") {
      this.moveIndex(1);
    }
  }

  private moveIndex(direction: number) {
    const assetIndex = this.getAssetsData.findIndex(
      (a) => a.slug === this.$route.params.assetSlug
    );
    const newIndex = assetIndex + direction;

    if (newIndex >= 0 && newIndex < this.getAssetsData.length) {
      const asset = this.getAssetsData[newIndex];
      const newPath = this.getFullAssetRouteAsString(asset);

      this.$router.push(newPath);
    }
  }

  mounted(): void {
    window.addEventListener("keydown", this.handleArrowKeyPress);
  }

  beforeUnmount(): void {
    window.removeEventListener("keydown", this.handleArrowKeyPress);
  }
}
