
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin } from "@/mixins";
import { PropType } from "vue";
import { AssetsInterface, AssetType } from "@/types";
import { jsonToQueryString } from "@/utils";
import debounce from "debounce";

@Options({
  name: "Model3DComponent",
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
})
export default class Model3DComponent extends mixins(RoutingUtilityMixin) {
  $refs!: {
    modelsIframe: HTMLFormElement;
  };
  private asset!: AssetsInterface;

  protected onScanOnload(): void {
    this.$refs.modelsIframe.contentWindow.OnReady(() => {
      this.setCustomStyle();

      const webViewerElement = this.$refs.modelsIframe.contentWindow.document;
      //check class changes
      const observer = new MutationObserver((mutationRecords: any) => {
        const slider = document.querySelector(".slider");
        const pinAssetButton = slider?.querySelector(".pin-asset-button");
        const [mutationRecord] = mutationRecords;

        if (mutationRecord.target.classList.contains("showTutorial")) {
          slider!.querySelectorAll(".slider-arrow").forEach((a: any) => {
            a.classList.add("is-hidden");
          });
          pinAssetButton?.classList.add("is-hidden");
        } else {
          slider!.querySelectorAll(".slider-arrow").forEach((a: any) => {
            a.classList.remove("is-hidden");
          });
          pinAssetButton?.classList.remove("is-hidden");
        }
      });

      observer.observe(webViewerElement.querySelector(".ANModelViewer"), {
        attributes: true,
        attributeFilter: ["class"],
        childList: false,
        characterData: false,
      });

      this.$refs.modelsIframe.addEventListener("mouseover", () =>
        document.body.classList.add("no-scroll")
      );

      this.$refs.modelsIframe.addEventListener("mouseleave", () =>
        document.body.classList.remove("no-scroll")
      );

      setTimeout(() => {
        if (this.asset!.modelsPosition && this.$refs.modelsIframe) {
          this.$refs.modelsIframe.contentWindow?.SetCameraState(
            this.asset!.modelsPosition
          );
        }
      }, 500);
    });
  }

  private setCustomStyle(): void {
    if (!this.$refs.modelsIframe) return;

    const webViewerElement = this.$refs.modelsIframe.contentWindow.document;

    if (webViewerElement && !this.hasFullscreenSupport()) {
      const buttonFullscreen =
        webViewerElement.body.getElementsByClassName("btnFullscreen")[0];

      if (buttonFullscreen) {
        buttonFullscreen.style.display = "none";
      }
    }
  }

  protected hasFullscreenSupport(): boolean {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    return [!iOS, !isSafari].every((a) => a);
  }

  protected getModel3DPath(models: string): string {
    const params = {
      wm: 0,
      autoplay: 1,
      lang: "en",
      hideButtons: 1,
      pl: models,
    };

    return `/web_viewer.html?${jsonToQueryString(params)}`;
  }

  private resizeHandler(): void {
    this.setCustomStyle();
  }

  beforeMount(): void {
    window.addEventListener(
      "resize",
      debounce(() => {
        this.resizeHandler();
      }, 200)
    );
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
